import Header from "components/header/Header";
import styles from "css/pages/landing/LandingHeaderAndHero.module.css";
import Header1 from "components/text/Header1";
import ColorClass from "types/enums/ColorClass";
import Body1 from "components/text/Body1";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import joinClasses from "utils/joinClasses";
import PfpSpritesheet from "components/pfp/PfpSpritesheet";
import CtaButton from "components/buttons/CtaButton";

export default function LandingHeaderAndHero(): JSX.Element {
  return (
    <div className={styles.containerOuter}>
      <div className={joinClasses(styles.bg, styles.fadeIn)}>
        <img className={styles.bgImage} src="/images/header-art.jpg" />
        <div className={styles.gradient} />
      </div>
      <div className={joinClasses(styles.header, styles.fadeIn)}>
        <Header />
      </div>
      <ResponsiveContainer>
        <div className={joinClasses(styles.hero, styles.fadeIn)}>
          <PfpSpritesheet />
          <div className={styles.title}>
            <Header1 colorClass={ColorClass.GreenDark} textAlign="center">
              welcome to the
            </Header1>
            <Header1
              colorClass={ColorClass.GreenDark}
              textTransform="uppercase"
            >
              Mycoverse
            </Header1>
          </div>
          <Body1
            className={styles.description}
            colorClass={ColorClass.YellowLight}
            textAlign="center"
          >
            The Mycoverse is a collection of 999 mushroom-inspired NFTs on the
            Solana blockchain. We’re on a mission to onboard the next 1 million
            artists into web3!
          </Body1>
          {/* TODO: finalize date */}
          <CtaButton className={styles.ctaButton} />
        </div>
      </ResponsiveContainer>
    </div>
  );
}
