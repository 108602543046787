import { PRESALE_DATE, PUBLIC_DATE } from "constants/Dates";
import dayjs from "utils/dates/dayjsex";

export default function getMintStage() {
  const now = dayjs();

  if (now.isBefore(PRESALE_DATE)) {
    return "before";
  }
  if (now.isBefore(PUBLIC_DATE)) {
    return "presale";
  }
  return "public";
}
