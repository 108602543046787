enum ColorValue {
  BackgroundOverlay = "rgba(0, 0, 0, 0.3)",
  Dimmer = "rgba(0, 0, 0, 0.36)",
  Error = "#ff7777",
  Green = "#127967",
  GreenDark = "#024236",
  GreenMuted = "#69B3A6",
  Pink = "#FFC6C6",
  PinkHover = "#ffb4b4",
  White = "#ffffff",
  YellowLight = "#FEFCD7",
  YellowLightHover = "#fffcc3",
}

export default ColorValue;
