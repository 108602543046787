import styles from "css/buttons/CtaButton.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import Body3 from "components/text/Body3";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import joinClasses from "utils/joinClasses";

import getMintStage from "utils/getMintStage";

type Props = {
  buttonTheme?: ButtonTheme;
  className?: string;
  colorClass?: ColorClass;
  showDescription?: boolean;
};

export default function CtaButton({
  buttonTheme = ButtonTheme.YellowLight,
  className,
  colorClass = ColorClass.YellowLight,
  showDescription = false,
}: Props): JSX.Element {
  return (
    <div className={joinClasses(className, styles.container)}>
      {/* TODO: finalize date */}
      {showDescription && (
        <Body3
          className={styles.description}
          colorClass={colorClass}
          textAlign="center"
        >
          {getMintStage() === "presale"
            ? "Wave 2 pre-sale is minting now!"
            : "Wave 2 public sale is minting now!"}
        </Body3>
      )}
      <ButtonWithText
        buttonTheme={buttonTheme}
        className={styles.button}
        fontClass={FontClass.ButtonText}
        href="https://discord.gg/j8hczUtZcr"
        style={showDescription ? {} : { marginTop: 0 }}
        type="link_external"
      >
        Join our Discord Community
      </ButtonWithText>
      {/* <ButtonWithText
        buttonTheme={buttonTheme}
        className={styles.button}
        fontClass={FontClass.ButtonText}
        href="/mint"
        style={showDescription ? {} : { marginTop: 0 }}
        type="link_internal"
      >
        Mint
      </ButtonWithText> */}
    </div>
  );
}
