import BackgroundOverlay from "components/loading/BackgroundOverlay";
import CloseButton from "components/buttons/CloseButton";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import joinClasses from "utils/joinClasses";
import styles from "css/header/MenuMobile.module.css";
import { useState } from "react";
import TwitterIcon24 from "components/icons/TwitterIcon24";
import DiscordIcon24 from "components/icons/DiscordIcon24";
import OutsideClickHideModal from "components/modal/OutsideClickHideModal";
import ElementId from "types/enums/ElementId";
import scrollElementIntoView from "utils/scrollElementIntoView";

function Button({
  children,
  elementId,
  onHide,
}: {
  children: string;
  elementId: ElementId;
  onHide: () => void;
}): JSX.Element {
  if (window.location.pathname !== "/") {
    return (
      <TextButton
        buttonTheme={TextButtonTheme.GreenDark}
        fontClass={FontClass.NavLink}
        href={`/#${elementId}`}
        type="link_external"
      >
        {children}
      </TextButton>
    );
  }

  return (
    <TextButton
      buttonTheme={TextButtonTheme.GreenDark}
      fontClass={FontClass.NavLink}
      onClick={() => {
        scrollElementIntoView(ElementId.Collection);
        onHide();
      }}
    >
      {children}
    </TextButton>
  );
}

type Props = {
  onHide: () => void;
};

export default function MenuMobile({ onHide }: Props): JSX.Element {
  const [slideOutClassName, setSlideOutClassName] = useState<string>("");
  const [backgroundOverlayClassName, setBackgroundOverlayClassName] =
    useState<string>(styles.backgroundOverlayFadeIn);

  const onHideWithSlide = () => {
    setBackgroundOverlayClassName(styles.backgroundOverlayFadeOut);
    setSlideOutClassName(styles.slideOut);
    setTimeout(onHide, 600);
  };

  return (
    <OutsideClickHideModal hideModal={onHideWithSlide}>
      <div className={styles.containerOuter}>
        <BackgroundOverlay className={backgroundOverlayClassName} />
        <ResponsiveContainer
          className={joinClasses(styles.container, slideOutClassName)}
        >
          <div className={styles.textButtons}>
            <div className={styles.firstRow}>
              <CloseButton
                className={styles.closeButton}
                colorValue={ColorValue.GreenDark}
                isShown={false}
              />
              <Button elementId={ElementId.Collection} onHide={onHide}>
                Collection
              </Button>
              <CloseButton
                className={styles.closeButton}
                colorValue={ColorValue.GreenDark}
                onClick={onHideWithSlide}
              />
            </div>
            <Button elementId={ElementId.Roadmap} onHide={onHide}>
              Journey
            </Button>
            <Button elementId={ElementId.Faqs} onHide={onHide}>
              FAQs
            </Button>
            <div className={styles.icons}>
              <a href="https://twitter.com/themycoverse">
                <TwitterIcon24 colorValue={ColorValue.GreenDark} />
              </a>
              <a href="https://discord.gg/j8hczUtZcr">
                <DiscordIcon24 colorValue={ColorValue.GreenDark} />
              </a>
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    </OutsideClickHideModal>
  );
}
