/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import styles from "css/pages/landing/LandingTeam.module.css";
import { useState } from "react";
import TextButton from "components/buttons/TextButton";
import TwitterIcon24 from "components/icons/TwitterIcon24";
import FadeInContainer from "components/misc/FadeInContainer";

import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Body2 from "components/text/Body2";
import Header2 from "components/text/Header2";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";

function ProjectLink({
  children,
  href,
}: {
  children: any;
  href: string;
}): JSX.Element {
  return (
    <TextButton
      buttonTheme={TextButtonTheme.YellowLight}
      display="inline"
      fontClass={FontClass.Body2SemiBold}
      href={href}
      type="link_external"
    >
      {children}
    </TextButton>
  );
}

function TeamMemberProfile({
  description,
  srcNft,
  srcReal,
  twitterHandle,
  twitterHref,
}: {
  description: string;
  srcNft: string;
  srcReal: string;
  twitterHandle: string;
  twitterHref: string;
}): JSX.Element {
  const [isReal, setIsReal] = useState(false);

  return (
    <div className={styles.teamMemberProfile}>
      <img
        className={styles.teamMemberProfileImage}
        onMouseOut={() => setIsReal(false)}
        onMouseOver={() => setIsReal(true)}
        src={srcNft}
        style={{ display: !isReal ? "block" : "none" }}
      />
      <img
        className={styles.teamMemberProfileImage}
        onMouseOut={() => setIsReal(false)}
        onMouseOver={() => setIsReal(true)}
        src={srcReal}
        style={{ display: isReal ? "block" : "none" }}
      />
      <TextButton
        buttonTheme={TextButtonTheme.YellowLight}
        className={styles.teamMemberProfileTwitter}
        fontClass={FontClass.Body1}
        href={twitterHref}
        icon={<TwitterIcon24 colorValue={ColorValue.YellowLight} />}
        type="link_external"
      >
        {twitterHandle}
      </TextButton>
      <Body2
        className={styles.teamMemberProfileDescription}
        colorClass={ColorClass.YellowLight}
      >
        {description}
      </Body2>
    </div>
  );
}

export default function LandingTeam(): JSX.Element {
  return (
    <div className={BackgroundColorClass.Green}>
      <FadeInContainer>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Header2
              colorClass={ColorClass.YellowLight}
              textAlign="center"
              textTransform="uppercase"
            >
              The Team
            </Header2>
            <Body2
              className={styles.description}
              colorClass={ColorClass.YellowLight}
              textAlign="center"
            >
              Hi, we’re Kat, &amp; Matt! We’ve been obsessed with mushroom
              foraging (IRL) for a long time, and wanted to bring this love to
              the NFT world. We’ve worked as a team for many years, and
              we&apos;ve always cared about supporting artists and creators.
              Now, we want to extend our mission to a community—the Mycoverse.
              Boogs started as one of the first members of the Mycoverse
              community—we hope you&apos;ll join us as well!
              <br />
              <br />
              We feel good about delivering on our roadmap promises (and
              beyond), but don’t take our word for it. Check out some of the
              past projects we’ve launched together:{" "}
              <ProjectLink href="https://niftypixels.io/">
                Nifty Pixels
              </ProjectLink>
              , <ProjectLink href="https://minymon.com">Minymon</ProjectLink>,
              and{" "}
              <ProjectLink href="https://tinyshroomstudio.com/projects/porta_penguin.html">
                Porta Penguin
              </ProjectLink>
              .
            </Body2>
            <div className={styles.teamMemberProfiles}>
              <TeamMemberProfile
                description="Art & design"
                srcNft="images/elves/katherine-elf2.jpg"
                srcReal="images/kat-mushroom3.jpg"
                twitterHandle="petrichorate"
                twitterHref="https://twitter.com/petrichorate"
              />
              <TeamMemberProfile
                description="Solana dev & engineering"
                srcNft="images/elves/matt-elf2.jpg"
                srcReal="images/matt-mushroom3.jpeg"
                twitterHandle="pencilflip"
                twitterHref="https://twitter.com/pencilflip"
              />
              <TeamMemberProfile
                description="Community manager"
                srcNft="images/elves/boogs-elf.png"
                srcReal="images/boogs-real.jpg"
                twitterHandle="boogsloose"
                twitterHref="https://twitter.com/boogsloose"
              />
            </div>
          </div>
        </ResponsiveContainer>
      </FadeInContainer>
    </div>
  );
}
