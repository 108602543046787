import "css/global/Global.css";

// Colors
import "css/global/colors/ColorVariables.css";
import "css/global/colors/BackgroundColorClasses.css";
import "css/global/colors/ColorClasses.css";

// Fonts
import "css/global/fonts/Facundo.css";
import "css/global/fonts/TanNimbus.css";
import "css/global/fonts/FontClasses.css";
import "css/global/fonts/FontVariables.css";
import smoothscroll from "smoothscroll-polyfill";

import Routes from "routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import Minymon from "components/misc/Minymon";
import "utils/firebase/firebaseApp";

function App() {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <Minymon />
    </>
  );
}

export default App;
