import Marquee from "react-fast-marquee";
import styles from "css/pages/mint/MintMarquee.module.css";
import { range } from "utils/range";
import joinClasses from "utils/joinClasses";
import GlobalClass from "types/enums/GlobalClass";

const SRCS = range(10).map((num) => `images/elves/mint-page/${num + 1}.png`);

export default function MintMarquee(): JSX.Element {
  return (
    <Marquee gradient={false} speed={55}>
      {/* Repeat for large screens (to avoid empty space) */}
      {[...SRCS, ...SRCS].map((value, index) => (
        <img
          className={joinClasses(styles.image, GlobalClass.HideText)}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          src={value}
        />
      ))}
    </Marquee>
  );
}
