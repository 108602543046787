import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDl6ykeERQOJTGalb_dxe5mZdElCodqxPI",
  authDomain: "mycoverse-3451e.firebaseapp.com",
  projectId: "mycoverse-3451e",
  storageBucket: "mycoverse-3451e.appspot.com",
  messagingSenderId: "666325145460",
  appId: "1:666325145460:web:ad6523a8e0cce0f0dcbe21",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
