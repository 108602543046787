import { Route, Routes as RoutesImport } from "react-router-dom";
import LandingPage from "components/pages/landing";
import MintPage from "components/pages/mint/MintPage";

// TODO: figure out how to set page titles
export default function Routes(): JSX.Element {
  return (
    <RoutesImport>
      <Route path="/" element={<LandingPage />} />
      <Route path="/mint" element={<MintPage />} />
    </RoutesImport>
  );
}
