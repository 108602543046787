import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/pages/landing/LandingCollection.module.css";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import Body2 from "components/text/Body2";
import PfpImage from "components/pfp/PfpImage";
import PfpImages from "components/pfp/PfpImages";
import FadeInContainer from "components/misc/FadeInContainer";
import ElementId from "types/enums/ElementId";

export default function LandingCollection(): JSX.Element {
  return (
    <div className={styles.containerOuter} id={ElementId.Collection}>
      <FadeInContainer>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Header2
              colorClass={ColorClass.YellowLight}
              textAlign="center"
              textTransform="uppercase"
            >
              The Collection
            </Header2>
            <Body2
              className={styles.description}
              colorClass={ColorClass.YellowLight}
              textAlign="center"
            >
              We’re starting with Elves of the Mycoverse—a collection of 999
              elves with over 250 unique traits. There are three distinct
              species of elves—city (most common), woodland, and fairy (most
              rare). Occasionally, a unique elf is discovered, which doesn’t
              belong to any of the three species.
              <br />
              <br />
              As the Mycoverse grows, we may add additional creatures.
            </Body2>
            <div className={styles.pfps}>
              <PfpImages>
                <PfpImage
                  src="images/elves/modern/modern1.png"
                  title="City Elves"
                  description="City elves live a similar lifestyle to humans, and build their sprawling cities at the edge of forests."
                  // smallDescription="1,500 ELVES"
                />
                <PfpImage
                  src="images/elves/woodland/woodland1.png"
                  title="Woodland Elves"
                  description="Woodland elves can only be found deep in the forest. They can use healing magic through their bond with nature."
                  // smallDescription="1,000 ELVES"
                />
                <PfpImage
                  src="images/elves/fairy/fairy2.png"
                  title="Fairy Elves"
                  description="Fairy elves live in cities in the sky. They spend their days flying from cloud to cloud, looking to cause a bit of mischief."
                  // smallDescription="800 ELVES"
                />
                <PfpImage
                  src="images/elves/unique/zombie.png"
                  title="Unique Elves"
                  description="A new unique elf is only spotted once per century... rumors say some of them resemble zombies, ghosts, or even solid gold 😳"
                  // smallDescription="33 ELVES"
                />
              </PfpImages>
            </div>
          </div>
        </ResponsiveContainer>
      </FadeInContainer>
    </div>
  );
}
