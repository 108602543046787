enum ColorClass {
  BackgroundOverlay = "colorBackgroundOverlay",
  Dimmer = "colorDimmer",
  Error = "colorError",
  Green = "colorGreen",
  GreenDark = "colorGreenDark",
  GreenMuted = "colorGreenMuted",
  Pink = "colorPink",
  PinkHover = "colorPinkHover",
  White = "colorWhite",
  YellowLight = "colorYellowLight",
  YellowLightHover = "colorYellowLightHover",
}

export default ColorClass;
