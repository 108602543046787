import {
  doc,
  updateDoc,
  increment,
  getFirestore,
  setDoc,
  getDoc,
} from "firebase/firestore";

export default async function incNumMinted(address: string): Promise<void> {
  const db = getFirestore();
  const docRef = doc(db, "users", address);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    await setDoc(docRef, {
      address,
    });
  }

  await updateDoc(docRef, {
    count: increment(1),
  });
}
