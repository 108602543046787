import styles from "css/pages/landing/LandingSolana.module.css";
import TextButton from "components/buttons/TextButton";
import SolanaIcon from "components/icons/SolanaIcon";
import FadeInContainer from "components/misc/FadeInContainer";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Body2 from "components/text/Body2";
import Header2 from "components/text/Header2";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";

export default function LandingSolana(): JSX.Element {
  return (
    <div className={BackgroundColorClass.GreenDark}>
      <FadeInContainer>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <SolanaIcon colorValue={ColorValue.YellowLight} />
            <Header2
              className={styles.title}
              colorClass={ColorClass.YellowLight}
              textAlign="center"
              textTransform="uppercase"
            >
              The Mycoverse runs on Solana
            </Header2>
            <Body2
              className={styles.description}
              colorClass={ColorClass.YellowLight}
              textAlign="center"
            >
              Many popular NFT projects use the Ethereum blockchain, which
              consumes enormous amounts of energy and has incredibly expensive
              gas fees. The Mycoverse is built on the Solana blockchain instead
              to minimize our environmental impact. Minting an NFT on Solana
              only uses as much energy as{" "}
              <TextButton
                buttonTheme={TextButtonTheme.YellowLight}
                display="inline"
                fontClass={FontClass.Body2SemiBold}
                href="https://solana.com/news/solana-energy-usage-report-november-2021#fn5"
                type="link_external"
              >
                two Google searches
              </TextButton>
              !
            </Body2>
          </div>
        </ResponsiveContainer>
      </FadeInContainer>
    </div>
  );
}
