declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "minymon-answer": any;
      "minymon-body": any;
      "minymon-dialog": any;
      "minymon-feedback": any;
      "minymon-music": any;
      "minymon-question": any;
    }
  }
}

export default function Minymon(): JSX.Element {
  return (
    <div style={{ position: "relative", zIndex: 2147483647 }}>
      <minymon-body
        enableFeed="true"
        enableTalk="true"
        idsString="ZmFhMjBjY2UtMGRiNi00MTMyLWEzNzctMmE4NjY5OWUyZDU5LnIzbWRpOFZzYUJ2ZkpEMjl2MDdtNQ=="
        infoTitle="Hi there!"
        theme="red"
      >
        <minymon-question question="🍄 What is the Mycoverse?" theme="red">
          <minymon-answer
            answer="The Mycoverse is a collection of 999 mushroom-inspired NFTs on the Solana blockchain. We’re on a mission to onboard the next 1 million artists into web3!"
            question="🍄 What is the Mycoverse?"
            theme="red"
          />
        </minymon-question>
        <minymon-question question="🧐 Who's making the Mycoverse?" theme="red">
          <minymon-answer
            answer='Creating the Mycoverse will require a community-driven effort. Currently, <a href="https://twitter.com/pencilflip">pencilflip</a> and <a href="https://twitter.com/petrichorate">petrichorate</a> are leading the charge.'
            question="🧐 Who's making the Mycoverse?"
            theme="red"
          />
        </minymon-question>
        <minymon-feedback
          description="Your message will be sent to the Mycoverse 🍄"
          mid="faa20cce-0db6-4132-a377-2a86699e2d59"
          theme="red"
          uid="r3mdi8VsaBvfJD29v07m5"
        />
        <minymon-dialog
          dialog="%5B%7B%22id%22%3A%228qSNX0XGvouSwgH0hViC3%22%2C%22prompt%22%3A%22Hi%2C%20how%27s%20it%20going%3F%22%2C%22responses%22%3A%5B%7B%22id%22%3A%22Ez6cDkgLU1LITVwMc7f1l%22%2C%22response%22%3A%22Poorly%22%2C%22nextPromptId%22%3A%2282POkkCg7F2X-v1TeSayA%22%7D%2C%7B%22id%22%3A%22qTzSggeaIiOZcInM72nTE%22%2C%22response%22%3A%22Good%22%2C%22nextPromptId%22%3A%22fylPnv_0Ep7kBKD82VAdq%22%7D%2C%7B%22id%22%3A%220sqYk50wgRmV8DUpF5aTg%22%2C%22response%22%3A%22Great%22%2C%22nextPromptId%22%3A%22H5GjdDp91rCFUjQrmJHl3%22%7D%5D%7D%2C%7B%22id%22%3A%2282POkkCg7F2X-v1TeSayA%22%2C%22prompt%22%3A%22Try%20eating%20some%20mushrooms%22%2C%22responses%22%3A%5B%5D%7D%2C%7B%22id%22%3A%22H5GjdDp91rCFUjQrmJHl3%22%2C%22prompt%22%3A%22You%20must%20be%20eating%20mushrooms%21%22%2C%22responses%22%3A%5B%5D%7D%2C%7B%22id%22%3A%22fylPnv_0Ep7kBKD82VAdq%22%2C%22prompt%22%3A%22I%20take%20it%20you%27ve%20been%20eating%20mushrooms%22%2C%22responses%22%3A%5B%5D%7D%5D"
          theme="red"
        />
      </minymon-body>
    </div>
  );
}
