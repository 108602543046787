import styles from "css/pfp/PfpSpritesheet.module.css";
import { useEffect } from "react";

export default function PfpSpritesheet(): JSX.Element {
  useEffect(() => {
    let i = 0;
    const elem = document.getElementById("spritesheet");
    let interval: any = null;
    setTimeout(() => {
      interval = setInterval(() => {
        if (i === 9) {
          elem!.style.objectPosition = "0";
          i = 0;
        } else {
          i += 1;
          elem!.style.objectPosition = `calc(var(--height-pfpSpritesheet) * -${i})`;
        }
      }, 900);
    }, 500);

    return () => {
      if (interval != null) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <img
      className={styles.spritesheet}
      id="spritesheet"
      src="/images/elves/elves-spritesheet4.png"
    />
  );
}
