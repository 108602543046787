import ColorValue from "types/enums/ColorValue";
import HamburgerButton from "components/buttons/HamburgerButton";
import MenuMobile from "components/header/MenuMobile";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/header/HeaderMobile.module.css";
import { useState } from "react";
import HeaderStyle from "types/enums/HeaderStyle";

type Props = {
  headerStyle: HeaderStyle;
};

export default function HeaderMobile({ headerStyle }: Props): JSX.Element {
  const [isMenuShown, setIsMenuShown] = useState<boolean>(false);

  return (
    <>
      {isMenuShown && <MenuMobile onHide={() => setIsMenuShown(false)} />}
      <div
        className={
          headerStyle === HeaderStyle.Gradient
            ? styles.containerOuterGradient
            : styles.containerOuterSolid
        }
      >
        <ResponsiveContainer className={styles.containerResponsive}>
          <div className={styles.containerInner}>
            <HamburgerButton
              colorValue={ColorValue.YellowLight}
              isShown={false}
            />
            <h3 className={styles.header}>Mycoverse</h3>
            <HamburgerButton
              colorValue={ColorValue.YellowLight}
              onClick={() => setIsMenuShown(true)}
            />
          </div>
        </ResponsiveContainer>
      </div>
    </>
  );
}
