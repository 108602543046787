import { useState } from "react";
import styles from "css/pages/landing/LandingFaqsItem.module.css";
import Body1SemiBold from "components/text/Body1SemiBold";
import PlainButton from "components/buttons/PlainButton";
import ChevronDownIcon from "components/icons/ChevronDownIcon";
import ColorValue from "types/enums/ColorValue";
import Body1 from "components/text/Body1";
import ChevronRightIcon from "components/icons/ChevronRightIcon";
import ColorClass from "types/enums/ColorClass";

type Props = {
  children: any;
  question: string;
};

export default function LandingFaqsItem({
  children,
  question,
}: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className={styles.questionAnswerContainer}>
      <PlainButton
        className={styles.questionContainer}
        onClick={() => {
          setIsExpanded((curr) => !curr);

          // So ricepaper can be adjusted
          const event = new Event("height_change");
          window.dispatchEvent(event);
        }}
        type="button"
      >
        <Body1SemiBold
          className={styles.question}
          colorClass={ColorClass.GreenDark}
        >
          {question}
        </Body1SemiBold>
        <div className={styles.chevron}>
          {isExpanded ? (
            <ChevronDownIcon colorValue={ColorValue.GreenDark} />
          ) : (
            <ChevronRightIcon colorValue={ColorValue.GreenDark} />
          )}
        </div>
      </PlainButton>
      {isExpanded ? (
        <Body1 colorClass={ColorClass.GreenDark} className={styles.answer}>
          {children}
        </Body1>
      ) : null}
    </div>
  );
}
