import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import * as anchor from "@project-serum/anchor";
import { existsOwnerSPLToken } from "utils/candy-machine-v2/candy-machine";

import getRpcHostFromNetwork from "utils/getRpcHostFromNetwork";
import { SOLANA_NETWORK } from "constants/CandyMachine";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

const defaultNetwork = SOLANA_NETWORK as WalletAdapterNetwork;
const connection = new anchor.web3.Connection(
  getRpcHostFromNetwork(defaultNetwork)
);

const useSplToken = () => {
  const wallet = useWallet();
  const [isSPLLoaded, setIsSPLLoaded] = useState(false);
  const [isSPLExists, setSPLExists] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(0);

  async function loadSPLToken() {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    const [isExistSPLToken, SPLTokenAmount] = await existsOwnerSPLToken(
      connection,
      wallet.publicKey
    );
    setSPLExists(isExistSPLToken);
    setTokenAmount(SPLTokenAmount);

    setIsSPLLoaded(true);
    console.log(
      `isSPLExists ${isExistSPLToken}`,
      " ",
      wallet.publicKey.toString()
    );
  }

  useEffect(() => {
    (async () => {
      await loadSPLToken();
    })();
  }, [wallet]);

  return { tokens: [isSPLLoaded, isSPLExists, tokenAmount], loadSPLToken };
};

export default useSplToken;
