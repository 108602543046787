import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/pages/landing/LandingFaqs.module.css";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import LandingFaqsItem from "components/pages/landing/LandingFaqsItem";
import ElementId from "types/enums/ElementId";
import FadeInContainer from "components/misc/FadeInContainer";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import { WAVE_1_SUPPLY } from "constants/Supply";

function ProjectLink({
  children,
  href,
}: {
  children: any;
  href: string;
}): JSX.Element {
  return (
    <TextButton
      buttonTheme={TextButtonTheme.GreenDark}
      display="inline"
      fontClass={FontClass.Body1SemiBold}
      href={href}
      type="link_external"
    >
      {children}
    </TextButton>
  );
}

export default function LandingFaqs(): JSX.Element {
  return (
    <div className={BackgroundColorClass.Pink} id={ElementId.Faqs}>
      <FadeInContainer>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Header2
              colorClass={ColorClass.GreenDark}
              textAlign="center"
              textTransform="uppercase"
            >
              FAQs
            </Header2>
            <div className={styles.questionsAndAnswers}>
              <LandingFaqsItem question="What's the goal of the Mycoverse?">
                Ultimately, we want the Mycoverse to be both an amazing brand
                and a welcoming community—all centered around onboarding the
                next 1 million artists into web3. We strongly believe that the
                metaverse needs a diverse set of artists and creators to
                succeed. We&apos;re going to achieve this by building tools that
                make it easy for small artists and creators to bring their
                talents into web3—the first step here is launching Nifty
                Generator. Lastly, if you’re passionate about bringing the
                Mycoverse to life in your own projects, we want to support you!
                <br />
                <br />
                We’re super excited to build the Mycoverse (and the metaverse)
                with you!
              </LandingFaqsItem>
              <LandingFaqsItem question="How will minting work?">
                We&apos;re minting in waves. The first wave was on December
                14th, 2021—we sold out {WAVE_1_SUPPLY} NFTs in 3 minutes!
                <br />
                <br />
                Dates of future waves are TBD! Each Mycoverse elf will be
                somewhere between 0.3 and 1.0 SOL (depends on the market), and
                only 5 elves can be minted per wallet.
                <br />
                <br />
                Out of the 999 elves, we have reserved 100 elves for the team
                and giveaways. The rest of the elves will all be for sale!
              </LandingFaqsItem>
              <LandingFaqsItem question="What's with the mushroom theme?">
                Aside from our love for mushroom foraging, there’s another
                reason why we wanted this project to be mushroom-themed.
                Mushrooms grow from vast networks of fungal threads called
                mycelium (the mushrooms we see are just the fruits—most of the
                mushroom is hiding underground as mycelium!). Mycelium is often
                called the “Earth’s natural internet”—it connects mushrooms,
                trees, and other plants, and allows them to transfer nutrients
                and information. About 90% of all land-based plants are actually
                connected through these amazing networks!
                <br />
                <br />
                We want the Mycoverse to be a mycelial network for the
                metaverse. The network is the Mycoverse community, where we can
                support each other and work together to bring more artists and
                creators into web3. And just as many mushrooms can grow from the
                same mycelial network, we&apos;re hoping that many cool projects
                and tools will emerge from the Mycoverse community, so we can
                bring our mission to life together.
              </LandingFaqsItem>
              <LandingFaqsItem question="Why is this the right team for this project?">
                Hi, Kat &amp; Matt here! We’re builders and creatives, and
                we&apos;ve always cared about supporting artists and creators.
                The Mycoverse is our way of extending our mission to a whole
                community of creators and collectors who care just as much about
                it as we do. We’ve previously made games, apps, zines, picture
                books, murals, and merch—and we’re excited to bring that
                experience and enthusiasm to this project! You can check out
                some of the past projects we’ve launched together:{" "}
                <ProjectLink href="https://niftypixels.io/">
                  Nifty Pixels
                </ProjectLink>
                , <ProjectLink href="https://minymon.com">Minymon</ProjectLink>,
                and{" "}
                <ProjectLink href="https://tinyshroomstudio.com/projects/porta_penguin.html">
                  Porta Penguin
                </ProjectLink>
                .
              </LandingFaqsItem>
              <LandingFaqsItem question="What's the utility of holding a Mycoverse NFT?">
                Besides everything on our roadmap, you&apos;ll get priority
                access and premium features for everything we build! You&apos;ll
                be able to influence which projects we choose to build, and have
                a direct line of communication to give feature requests and
                submit feedback. Finally, if you&apos;re excited by our mission
                of onboarding 1 million artists into the metaverse, we&apos;ll
                do everything we can to support you!
              </LandingFaqsItem>
              <LandingFaqsItem question="Do I have to be an artist to join the Mycoverse?">
                Nope, everyone is welcome! The tools we build will be focused
                around helping artists and creators get involved with web3, but
                we’ll also be doing lots of fun things (like the virtual
                foraging trips!) for everyone, artists and non-artists alike.
              </LandingFaqsItem>
              <LandingFaqsItem question="What can I do with my Mycoverse NFTs?">
                You are free to do anything with them under a non-exclusive
                license.
              </LandingFaqsItem>
            </div>
          </div>
        </ResponsiveContainer>
      </FadeInContainer>
    </div>
  );
}
