import ColorValue from "types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export default function SolanaIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="68"
      height="59"
      viewBox="0 0 68 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3023 44.9889C12.5155 44.7563 12.7754 44.5705 13.0652 44.4433C13.355 44.316 13.6684 44.2502 13.9853 44.25L65.9147 44.2921C66.1358 44.2926 66.3521 44.3567 66.5372 44.4767C66.7223 44.5967 66.8683 44.7674 66.9574 44.9681C67.0465 45.1688 67.0749 45.3908 67.0392 45.6072C67.0035 45.8236 66.9052 46.0251 66.7562 46.1872L55.6977 58.2611C55.4843 58.4938 55.2243 58.6798 54.9342 58.807C54.6442 58.9343 54.3304 59 54.0132 59L2.08537 58.9579C1.86423 58.9574 1.64797 58.8933 1.46287 58.7733C1.27777 58.6533 1.1318 58.4826 1.04268 58.2819C0.953559 58.0812 0.925134 57.8592 0.960848 57.6428C0.996562 57.4264 1.09489 57.2249 1.24387 57.0628L12.3023 44.9889ZM66.7562 34.9378C66.9052 35.0999 67.0035 35.3014 67.0392 35.5178C67.0749 35.7342 67.0465 35.9562 66.9574 36.1569C66.8683 36.3576 66.7223 36.5283 66.5372 36.6483C66.3521 36.7683 66.1358 36.8324 65.9147 36.8329L13.9868 36.875C13.6696 36.875 13.3559 36.8093 13.0658 36.682C12.7758 36.5548 12.5158 36.3688 12.3023 36.1361L1.24387 24.0551C1.09489 23.8931 0.996562 23.6916 0.960848 23.4752C0.925134 23.2588 0.953559 23.0368 1.04268 22.8361C1.1318 22.6354 1.27777 22.4647 1.46287 22.3447C1.64797 22.2247 1.86423 22.1605 2.08537 22.1601L54.0147 22.118C54.3316 22.1182 54.6451 22.184 54.9349 22.3113C55.2246 22.4385 55.4845 22.6243 55.6977 22.8569L66.7562 34.9378ZM12.3023 0.738905C12.5155 0.506358 12.7754 0.320516 13.0652 0.193278C13.355 0.0660402 13.6684 0.000209368 13.9853 0L65.9147 0.0421429C66.1358 0.0425887 66.3521 0.106704 66.5372 0.226699C66.7223 0.346695 66.8683 0.517407 66.9574 0.7181C67.0465 0.918792 67.0749 1.14083 67.0392 1.35724C67.0035 1.57364 66.9052 1.77511 66.7562 1.93717L55.6977 14.0111C55.4843 14.2438 55.2243 14.4298 54.9342 14.557C54.6442 14.6843 54.3304 14.75 54.0132 14.75L2.08537 14.7079C1.86423 14.7074 1.64797 14.6433 1.46287 14.5233C1.27777 14.4033 1.1318 14.2326 1.04268 14.0319C0.953559 13.8312 0.925134 13.6092 0.960848 13.3928C0.996562 13.1764 1.09489 12.9749 1.24387 12.8128L12.3023 0.738905Z"
        fill={colorValue}
      />
    </svg>
  );
}
