import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/header/HeaderDesktop.module.css";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import TwitterIcon from "components/icons/TwitterIcon";
import ColorValue from "types/enums/ColorValue";
import DiscordIcon from "components/icons/DiscordIcon";
import ElementId from "types/enums/ElementId";
import scrollElementIntoView from "utils/scrollElementIntoView";
import HeaderStyle from "types/enums/HeaderStyle";

function SectionButton({
  children,
  elementId,
}: {
  children: string;
  elementId: ElementId;
}): JSX.Element {
  if (window.location.pathname !== "/") {
    return (
      <TextButton
        buttonTheme={TextButtonTheme.YellowLight}
        fontClass={FontClass.NavLink}
        href={`/#${elementId}`}
        type="link_external"
      >
        {children}
      </TextButton>
    );
  }

  return (
    <TextButton
      buttonTheme={TextButtonTheme.YellowLight}
      fontClass={FontClass.NavLink}
      onClick={() => scrollElementIntoView(elementId)}
    >
      {children}
    </TextButton>
  );
}

type Props = {
  headerStyle: HeaderStyle;
};

export default function HeaderDesktop({ headerStyle }: Props): JSX.Element {
  return (
    <div
      className={
        headerStyle === HeaderStyle.Gradient
          ? styles.containerOuterGradient
          : styles.containerOuterSolid
      }
    >
      <ResponsiveContainer>
        <div className={styles.container}>
          <div className={styles.center}>
            <a href="/" style={{ textDecoration: "none" }}>
              <h2 className={styles.header}>Mycoverse</h2>
            </a>
          </div>
          <div className={styles.navButtons}>
            <SectionButton elementId={ElementId.Collection}>
              Collection
            </SectionButton>
            <SectionButton elementId={ElementId.Roadmap}>Journey</SectionButton>
            <SectionButton elementId={ElementId.Faqs}>FAQs</SectionButton>
          </div>
          <div className={styles.icons}>
            <a href="https://twitter.com/themycoverse">
              <TwitterIcon colorValue={ColorValue.YellowLight} />
            </a>
            <a href="https://discord.gg/j8hczUtZcr">
              <DiscordIcon colorValue={ColorValue.YellowLight} />
            </a>
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
