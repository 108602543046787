import MintBeforePage from "components/pages/mint/before/MintBeforePage";
import MintDuringPage from "components/pages/mint/during/MintDuringPage";
import MintPresalePage from "components/pages/mint/presale/MintPresalePage";
import { PRESALE_DATE, PUBLIC_DATE } from "constants/Dates";
import dayjs from "utils/dates/dayjsex";

export default function MintPage(): JSX.Element {
  const now = dayjs();

  if (now.isBefore(PRESALE_DATE)) {
    return <MintPresalePage />;
  }
  if (now.isBefore(PUBLIC_DATE)) {
    return <MintPresalePage />;
  }
  return <MintDuringPage />;
}
