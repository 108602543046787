import LandingCurlySeparator from "components/pages/landing/LandingCurlySeparator";
import LandingHeaderAndHero from "components/pages/landing/LandingHeaderAndHero";
import LandingOrigin from "components/pages/landing/LandingOrigin";
import LandingCollection from "components/pages/landing/LandingCollection";
import LandingRoadmap from "components/pages/landing/LandingRoadmap";
import LandingDonation from "components/pages/landing/LandingDonation";
// import LandingRarity from "components/pages/landing/LandingRarity";
import LandingSolana from "components/pages/landing/LandingSolana";
import LandingTeam from "components/pages/landing/LandingTeam";
import LandingBottom from "components/pages/landing/LandingBottom";
import LandingFooter from "components/pages/landing/LandingFooter";
import LandingFaqs from "components/pages/landing/LandingFaqs";
// import LandingRicePaper from "components/pages/landing/LandingRicePaper";

export default function LandingPage(): JSX.Element {
  return (
    <>
      {/* <LandingRicePaper /> */}
      <div>
        <LandingHeaderAndHero />
        <LandingCurlySeparator />
        <LandingOrigin />
        <LandingCollection />
        <LandingRoadmap />
        <LandingDonation />
        {/* <LandingRarity /> */}
        <LandingTeam />
        <LandingSolana />
        <LandingFaqs />
        <LandingBottom />
        <LandingFooter />
      </div>
    </>
  );
}
