import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/pages/landing/LandingDonation.module.css";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import Body2 from "components/text/Body2";
import FadeInContainer from "components/misc/FadeInContainer";

export default function LandingDonation(): JSX.Element {
  return (
    <div className={BackgroundColorClass.Pink}>
      <FadeInContainer>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Header2
              colorClass={ColorClass.GreenDark}
              textAlign="center"
              textTransform="uppercase"
            >
              SUPPORTING OUTDOOR EDUCATION
            </Header2>
            <Body2
              className={styles.description}
              colorClass={ColorClass.GreenDark}
              textAlign="center"
            >
              10% of minting profits will be donated to nonprofit organizations
              supporting outdoor and environmental education. We&apos;ll decide
              as a community which organizations to donate to.
            </Body2>
          </div>
        </ResponsiveContainer>
      </FadeInContainer>
    </div>
  );
}
