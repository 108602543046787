import styles from "css/header/Header.module.css";
import HeaderDesktop from "components/header/HeaderDesktop";
import HeaderMobile from "components/header/HeaderMobile";
import HeaderStyle from "types/enums/HeaderStyle";

type Props = {
  headerStyle?: HeaderStyle;
};

export default function Header({
  headerStyle = HeaderStyle.Gradient,
}: Props): JSX.Element {
  return (
    <>
      <div className={styles.mobileContainer}>
        <HeaderMobile headerStyle={headerStyle} />
      </div>
      <div className={styles.desktopContainer}>
        <HeaderDesktop headerStyle={headerStyle} />
      </div>
    </>
  );
}
