import ResponsiveContainer from "components/containers/ResponsiveContainer";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import styles from "css/pages/landing/LandingBottom.module.css";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import FadeInContainer from "components/misc/FadeInContainer";
import CtaButton from "components/buttons/CtaButton";
import ButtonTheme from "types/enums/ButtonTheme";

export default function LandingBottom(): JSX.Element {
  return (
    <div className={BackgroundColorClass.YellowLight}>
      <img className={styles.clouds} src="images/clouds.svg" />
      <ResponsiveContainer>
        <FadeInContainer>
          <div className={styles.containerInner}>
            <Header2
              colorClass={ColorClass.GreenDark}
              textAlign="center"
              textTransform="uppercase"
            >
              BE PART OF THE MYCOVERSE
            </Header2>
            <CtaButton
              buttonTheme={ButtonTheme.Pink}
              className={styles.ctaButton}
              colorClass={ColorClass.GreenDark}
              showDescription={false}
            />
          </div>
        </FadeInContainer>
      </ResponsiveContainer>
    </div>
  );
}
