import Header from "components/header/Header";
import LandingFooter from "components/pages/landing/LandingFooter";
import HeaderStyle from "types/enums/HeaderStyle";
import styles from "css/pages/mint/MintDuringPage.module.css";
import MintMarquee from "components/pages/mint/MintMarquee";
import ColorClass from "types/enums/ColorClass";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import PresaleButton from "components/buttons/PresaleButton";
import Header2 from "components/text/Header2";
import NavLink from "components/text/NavLink";
import Body2 from "components/text/Body2";

import { SolanaContextProvider } from "context/SolanaContext";
import { PUBLIC_DATE } from "constants/Dates";
import { WAVE_2_SUPPLY } from "constants/Supply";
import { WAVE_2_PRICE } from "constants/Price";

function DescriptionItem({
  label,
  value,
}: {
  label: string;
  value: string;
}): JSX.Element {
  return (
    <Body2
      className={styles.descriptionItem}
      colorClass={ColorClass.YellowLight}
      textAlign="center"
    >
      <b>{label}:</b> {value}
    </Body2>
  );
}

function Info(): JSX.Element {
  return (
    <ResponsiveContainer className={styles.info}>
      <Header2
        colorClass={ColorClass.YellowLight}
        textAlign="center"
        textTransform="uppercase"
      >
        Join the Mycoverse
      </Header2>
      <NavLink
        className={styles.description}
        colorClass={ColorClass.YellowLight}
        textAlign="center"
        textTransform="uppercase"
      >
        PRESALE IS LIVE: ELVES OF THE MYCOVERSE wave 2
      </NavLink>
      <div className={styles.descriptionItems}>
        <DescriptionItem
          label="Public mint opens"
          value={`${PUBLIC_DATE.format("MMMM D, YYYY [at] h:mm a z")}`}
        />
        <DescriptionItem
          label="Wave 2 supply"
          value={`${WAVE_2_SUPPLY} elf NFTs`}
        />
        <DescriptionItem label="Price" value={WAVE_2_PRICE} />
        <PresaleButton className={styles.mintButton} />
      </div>
    </ResponsiveContainer>
  );
}

export default function MintPresalePage(): JSX.Element | null {
  // Only enable minting page when in production
  return (
    <div className={styles.containerOuter}>
      <SolanaContextProvider>
        <Header headerStyle={HeaderStyle.Solid} />
        <div className={styles.body}>
          <Info />
          <MintMarquee />
        </div>
        <LandingFooter />
      </SolanaContextProvider>
    </div>
  );
}
