import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/pages/landing/LandingOrigin.module.css";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import NavLink from "components/text/NavLink";
import Body2 from "components/text/Body2";
import FadeInContainer from "components/misc/FadeInContainer";

export default function LandingOrigin(): JSX.Element {
  return (
    <div className={styles.containerOuter}>
      <FadeInContainer>
        <ResponsiveContainer className={styles.top}>
          <Header2
            colorClass={ColorClass.YellowLight}
            textAlign="center"
            textTransform="uppercase"
          >
            Origins of the Mycoverse
          </Header2>
          <NavLink
            className={styles.definition}
            colorClass={ColorClass.YellowLight}
            textAlign="center"
          >
            my·col·o·gy (mycology) = the scientific study of fungi
          </NavLink>
          <Body2
            className={styles.description}
            colorClass={ColorClass.YellowLight}
            textAlign="center"
          >
            In the Mycoverse, all creatures emerge from mushrooms at birth, and
            carry their distinctive mushroom caps their whole lives. 50
            different types of mushrooms have been discovered (to date).
            Forecasters say that early next year, elves are going to start
            emerging from their mushrooms. Will you be one of them?
          </Body2>
        </ResponsiveContainer>
        <div className={styles.originArtContainer}>
          <div className={styles.originArtGradient} />
          <img className={styles.originArt} src="/images/origin-art.png" />
        </div>
      </FadeInContainer>
    </div>
  );
}
