import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/pages/landing/LandingRoadmap.module.css";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import Body2 from "components/text/Body2";
import Body3SemiBold from "components/text/Body3SemiBold";
import Body1 from "components/text/Body1";
import Body3 from "components/text/Body3";
import ChevronDownIcon from "components/icons/ChevronDownIcon";
import ColorValue from "types/enums/ColorValue";
import ElementId from "types/enums/ElementId";
import FadeInContainer from "components/misc/FadeInContainer";
import { WAVE_1_SUPPLY } from "constants/Supply";

const ROADMAP_ITEMS = [
  {
    dateOrStatus: "✅ Complete",
    description: "",
    title: "Mycoverse website launch",
  },
  {
    dateOrStatus: "✅ Complete",
    description:
      "As part of our mission to onboard the next 1 million artists into web3, we want to use what we’ve learned from the Mycoverse to help other creators! We’ve turned our code from this project into an easy tool that any artist can use to randomly generate NFTs with different sets of traits (complete with metadata). We’ll be releasing it for free, to everyone, but Mycoverse NFT holders will get premium features and priority access to future tools!",
    src: "images/nifty-generator.png",
    title: "Nifty Generator tool release",
  },
  {
    dateOrStatus: "✅ Complete",
    description: `Our first wave! ${WAVE_1_SUPPLY} NFTs sold out in 3 minutes 😄`,
    src: "images/launch-wave1.png",
    title: "Elves of the Mycoverse NFTs—wave 1",
  },
  {
    dateOrStatus: "✅ Complete",
    description: `We released 689 NFTs for our second wave—sold out!`,
    title: "Elves of the Mycoverse NFTs—wave 2",
  },
  {
    dateOrStatus: "✅ Complete",
    description: "We are listed on Magic Eden!",
    title: "Get Mycoverse NFTs listed on secondary marketplaces",
  },
  {
    dateOrStatus: "March 2022",
    description:
      "Mycoverse residents will decide on the first nonprofit we’ll donate to.",
    title: "Community donation",
  },
  {
    dateOrStatus: "March 2022",
    description:
      "All Mycoverse residents will be entered into a raffle where 5 people will win a custom Mycoverse NFT, hand drawn by our team!",
    title: "Custom Mycoverse NFT raffle",
  },
  {
    dateOrStatus: "March 2022",
    description:
      "We’ll open a merch shop featuring mushroom and Mycoverse-themed goods. We’re planning to have custom enamel pins, tees, and other merch. We’ll also be taking special requests to make custom pieces of merch with your own unique Mycoverse character!",
    src: "images/merch.png",
    title: "Mycoverse merch shop",
  },
  {
    dateOrStatus: "June 2022",
    description:
      "Minymons are virtual pets that live on your website and can answer common questions, gather feedback, and entertain visitors. Mycoverse residents will receive an exclusive Mycoverse minymon that you can customize for your own website (like the one living at the corner of this site!).",
    src: "images/minymons.png",
    title: "Mycoverse x Minymon collaboration",
  },
  {
    dateOrStatus: "August 2022",
    description:
      "Rather than a simple airdrop, we’re creating a virtual foraging experience where you can forage for your own new mushroom NFTs! Token holders will be granted entry into a digital forest where you’ll have a set time limit to search for and uncover new NFTs (we’re still working on the experience, but here’s a snapshot from our prototype!).",
    title: "Virtual foraging trips",
    videoId: "e9f975681e8b7c76c4f641a77ebf1a90",
  },
  {
    dateOrStatus: "The Future",
    description:
      "We want our community to help us decide how to grow the Mycoverse in the future. We have lots of ideas—artist collaborations, story books, 3D Mycoverse characters—but we want you to choose!",
    title: "Lots more to come!",
  },
];

function RoadmapItem({
  dateOrStatus,
  description,
  src,
  title,
  videoId,
}: {
  dateOrStatus: string;
  description: string;
  src?: string;
  title: string;
  videoId?: string;
}): JSX.Element {
  return (
    <div className={styles.roadmapItem}>
      <Body3SemiBold
        className={styles.roadmapDate}
        colorClass={ColorClass.GreenMuted}
        textTransform="uppercase"
      >
        {dateOrStatus}
      </Body3SemiBold>
      <Body1 colorClass={ColorClass.YellowLight}>{title}</Body1>
      <Body3 colorClass={ColorClass.YellowLight}>{description}</Body3>
      {src != null && <img className={styles.roadmapItemImage} src={src} />}
      {videoId != null && (
        <div className={styles.roadmapItemVideo}>
          <iframe
            title="video"
            src={`https://iframe.videodelivery.net/${videoId}?muted=true&preload=true&loop=true&autoplay=true&controls=false`}
            style={{
              border: "none",
              borderRadius: 24,
              // pointerEvents: "none",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          />
        </div>
      )}
    </div>
  );
}

function RoadmapItems(): JSX.Element {
  return (
    <div className={styles.roadmapItems}>
      <div className={styles.startDiamond} />
      {ROADMAP_ITEMS.map((item) => (
        <RoadmapItem key={item.title} {...item} />
      ))}
      <div className={styles.chevronDown}>
        <ChevronDownIcon colorValue={ColorValue.YellowLight} />
      </div>
    </div>
  );
}

export default function LandingRoadmap(): JSX.Element {
  return (
    <div className={styles.containerOuter} id={ElementId.Roadmap}>
      <FadeInContainer
        threshold={
          typeof window !== "undefined" && window.innerWidth < 768 ? 0.03 : 0.07
        }
      >
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Header2
              colorClass={ColorClass.YellowLight}
              textAlign="center"
              textTransform="uppercase"
            >
              Journey
            </Header2>
            <Body2
              className={styles.description}
              colorClass={ColorClass.YellowLight}
              textAlign="center"
            >
              Our main goal is to build a welcoming community and onboard the
              next 1 million artists into web3. The first step in our journey is
              launching Elves of the Mycoverse. But we have many more exciting
              plans up our stipes...
            </Body2>
            <RoadmapItems />
          </div>
        </ResponsiveContainer>
      </FadeInContainer>
    </div>
  );
}
