import styles from "css/pfp/PfpImage.module.css";
import Body2 from "components/text/Body2";
import Body3 from "components/text/Body3";
import ColorClass from "types/enums/ColorClass";

type Props = {
  description: string;
  smallDescription?: string;
  src: string;
  title: string;
};

export default function PfpImage({
  description,
  smallDescription,
  src,
  title,
}: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <img className={styles.pfp} src={src} />
      <Body2
        className={styles.title}
        colorClass={ColorClass.YellowLight}
        textAlign="center"
      >
        {title}
      </Body2>
      {smallDescription != null && (
        <div className={styles.smallDescription}>{smallDescription}</div>
      )}
      <Body3
        className={styles.description}
        colorClass={ColorClass.YellowLight}
        textAlign="center"
      >
        {description}
      </Body3>
    </div>
  );
}
