import { useEffect, useState, useMemo } from "react";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress, Snackbar } from "@material-ui/core";

import * as anchor from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useWalletDialog } from "@solana/wallet-adapter-material-ui";
import {
  CandyMachineAccount,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "utils/candy-machine-v2/candy-machine";
import { CANDY_MACHINE_ID, CANDY_MACHINE_OFFSET } from "constants/CandyMachine";
import getNumMinted from "utils/firebase/getNumMinted";
import incNumMinted from "utils/firebase/incNumMinted";

import styles from "css/buttons/MintButton.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import Body3 from "components/text/Body3";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import joinClasses from "utils/joinClasses";
import useSolanaContext from "hooks/useSolanaContext";

const candyMachineId = new anchor.web3.PublicKey(CANDY_MACHINE_ID!);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

type Props = {
  buttonTheme?: ButtonTheme;
  className?: string;
  colorClass?: ColorClass;
  showDescription?: boolean;
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default function MintButton({
  buttonTheme = ButtonTheme.YellowLight,
  className,
  colorClass = ColorClass.YellowLight,
  showDescription = true,
}: Props): JSX.Element {
  const { connection } = useSolanaContext();
  const { setOpen } = useWalletDialog();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();

  async function checkTotalMint(address: string) {
    const firebaseNum = await getNumMinted(address);
    setTotalMinted(firebaseNum);
    return firebaseNum;
  }

  const refreshCandyMachineState = () => {
    (async () => {
      if (wallet == null) {
        return;
      }

      console.log(
        "before getCandyMachineState",
        wallet,
        connection,
        candyMachineId
      );

      const candyMachineState = await getCandyMachineState(
        wallet as anchor.Wallet,
        candyMachineId,
        connection
      );

      console.log(candyMachineState);

      setItemsAvailable(
        candyMachineState.state.itemsAvailable - CANDY_MACHINE_OFFSET
      );
      setItemsRemaining(
        Math.max(
          0,
          candyMachineState.state.itemsRemaining - CANDY_MACHINE_OFFSET
        )
      );
      setIsSoldOut(
        candyMachineState.state.itemsRemaining - CANDY_MACHINE_OFFSET === 0
      );
      setCandyMachine(candyMachineState);

      await checkTotalMint(wallet.publicKey.toString());
      setIsLoaded(true);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            txTimeout,
            connection,
            "singleGossip",
            true
          );
        }

        if (!status?.err) {
          setTotalMinted((totalMinted as number) + 1);
          setItemsRemaining((itemsRemaining as number) - 1);
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
          await incNumMinted(wallet.publicKey.toString());
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.includes("0x1778")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        } else if (error.message.includes("0x177b")) {
          message = `Minting period hasn't started yet.`;
        } else if (error.message.indexOf("0x138")) {
          message = `Failed to sign transaction.`;
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        }
      } else if (error.msg && error.code === 311) {
        message = `SOLD OUT!`;
        setIsSoldOut(true);
      } else if (error.msg && error.code === 312) {
        message = `Minting period hasn't started yet.`;
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsMinting(false);
      // refreshCandyMachineState();
    }
  };

  useEffect(refreshCandyMachineState, [wallet, connection]);

  return (
    <div className={joinClasses(className, styles.container)}>
      {wallet && isLoaded && (
        <>
          {isSoldOut && (
            <ButtonWithText
              buttonTheme={buttonTheme}
              className={styles.button}
              disabled
              fontClass={FontClass.ButtonText}
              style={showDescription ? {} : { marginTop: 0 }}
            >
              Sold Out
            </ButtonWithText>
          )}
          {!isSoldOut && isMinting && (
            <ButtonWithText
              buttonTheme={buttonTheme}
              className={styles.button}
              fontClass={FontClass.ButtonText}
              style={showDescription ? {} : { marginTop: 0 }}
            >
              <CircularProgress color="inherit" size={32} />
            </ButtonWithText>
          )}
          {!isSoldOut && !isMinting && (
            <ButtonWithText
              buttonTheme={buttonTheme}
              className={styles.button}
              fontClass={FontClass.ButtonText}
              style={showDescription ? {} : { marginTop: 0 }}
              onClick={onMint}
            >
              Mint
            </ButtonWithText>
          )}
        </>
      )}

      {wallet && !isLoaded && (
        <ButtonWithText
          buttonTheme={buttonTheme}
          className={styles.button}
          fontClass={FontClass.ButtonText}
          style={showDescription ? {} : { marginTop: 0 }}
        >
          Loading...
        </ButtonWithText>
      )}

      {!wallet && (
        <ButtonWithText
          buttonTheme={buttonTheme}
          className={styles.button}
          fontClass={FontClass.ButtonText}
          onClick={() => setOpen(true)}
          style={showDescription ? {} : { marginTop: 0 }}
        >
          Connect Wallet to Mint
        </ButtonWithText>
      )}

      {wallet && isLoaded && (
        <Body3
          className={styles.description}
          colorClass={colorClass}
          textAlign="center"
        >
          {totalMinted} minted
        </Body3>
      )}

      {wallet && isLoaded && (
        <Body3
          className={styles.description}
          colorClass={colorClass}
          textAlign="center"
        >
          {itemsRemaining} / {itemsAvailable} remaining
        </Body3>
      )}

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
