import joinClasses from "utils/joinClasses";
import styles from "css/misc/FadeInContainer.module.css";
import { useInView } from "react-intersection-observer";

type Props = {
  children: any;
  className?: string;
  threshold?: number;
};

export default function FadeInContainer({
  children,
  className,
  threshold,
}: Props): JSX.Element {
  const { inView, ref } = useInView({
    threshold:
      threshold != null
        ? threshold
        : typeof window !== "undefined" && window.innerWidth < 768
        ? 0.05
        : 0.15,
    triggerOnce: true,
  });
  const classNameFadeIn = inView ? styles.fadeIn : styles.animationInitial;

  return (
    <div className={joinClasses(classNameFadeIn, className)} ref={ref}>
      {children}
    </div>
  );
}
