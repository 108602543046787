enum BackgroundColorClass {
  BackgroundOverlay = "backgroundColorBackgroundOverlay",
  Dimmer = "backgroundColorDimmer",
  Error = "backgroundColorError",
  Green = "backgroundColorGreen",
  GreenDark = "backgroundColorGreenDark",
  GreenMuted = "backgroundColorGreenMuted",
  Pink = "backgroundColorPink",
  PinkHover = "backgroundColorPinkHover",
  White = "backgroundColorWhite",
  YellowLight = "backgroundColorYellowLight",
  YellowLightHover = "backgroundColorYellowLightHover",
}

export default BackgroundColorClass;
