import styles from "css/pages/landing/LandingFooter.module.css";
import TextButton from "components/buttons/TextButton";
import DiscordIcon from "components/icons/DiscordIcon";
import TwitterIcon from "components/icons/TwitterIcon";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Body2 from "components/text/Body2";
import Header2 from "components/text/Header2";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import ElementId from "types/enums/ElementId";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";
import joinClasses from "utils/joinClasses";
import scrollElementIntoView from "utils/scrollElementIntoView";

function Button({
  children,
  id,
}: {
  children: any;
  id: ElementId;
}): JSX.Element {
  if (window.location.pathname !== "/") {
    return (
      <TextButton
        buttonTheme={TextButtonTheme.YellowLight}
        fontClass={FontClass.NavLink}
        href={`/#${id}`}
        type="link_external"
      >
        {children}
      </TextButton>
    );
  }

  return (
    <TextButton
      buttonTheme={TextButtonTheme.YellowLight}
      fontClass={FontClass.NavLink}
      onClick={() => scrollElementIntoView(id)}
    >
      {children}
    </TextButton>
  );
}

function Buttons(): JSX.Element {
  return (
    <div className={styles.buttons}>
      {/* TODO: implement this */}
      {/* <Button id={ElementId.Rarity}>Mint</Button> */}
      {/* <TextButton
            buttonTheme={TextButtonTheme.YellowLight}
            fontClass={FontClass.NavLink}
            href="/mint"
            type="link_internal"
            >
            Mint
            </TextButton> */}
      <Button id={ElementId.Collection}>Collection</Button>
      <Button id={ElementId.Roadmap}>Journey</Button>
      <Button id={ElementId.Faqs}>FAQs</Button>
    </div>
  );
}

export default function LandingFooter(): JSX.Element {
  return (
    <div className={BackgroundColorClass.GreenDark}>
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <Header2
            className={styles.muted}
            colorClass={ColorClass.YellowLight}
            textAlign="center"
            textTransform="uppercase"
          >
            Mycoverse
          </Header2>
          <Body2
            className={joinClasses(styles.description, styles.muted)}
            colorClass={ColorClass.YellowLight}
            textAlign="center"
          >
            Putting the fungi in non-fungible tokens
          </Body2>
          <Buttons />
          <div className={styles.icons}>
            <a href="https://twitter.com/themycoverse">
              <TwitterIcon colorValue={ColorValue.YellowLight} />
            </a>
            <a href="https://discord.gg/j8hczUtZcr">
              <DiscordIcon colorValue={ColorValue.YellowLight} />
            </a>
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
